import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Diagnoses } from '../main/dashboard/navbar/diagnoses/diagnoses.class';

@Injectable({
  providedIn: 'root'
})
export class DiagnosesService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create new diagnose
   * @param diagnose 
   */
  createDiagnose(diagnose): Observable<any> {
    return this.apiService.post('/diagnose/new', diagnose)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * 
   * @param obj get all diagnose details
   */
  getAllDiagnoses(obj): Observable<any> {
    return this.apiService.post('/diagnose/getDiagnoses', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit diagnose details
   * @param obj 
   */
  editDiagnose(obj) {
    return this.apiService.put('/diagnose/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete diagnose
   *  @param obj 
   */
  deleteDiagnose(obj) {
    return this.apiService.post('/diagnose/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * suggest diagnoses when typing diagnose name in add dialog
   * @param filter 
   * @param page 
   */
  searchDiagnoses(filter: { name: string } = { name: '' }, page = 1): Observable<any> {
    return this.apiService.post('/diagnose/getAll', {})
      .pipe(
        tap((response: any) => {
          response.data = response.data
            .map(diagnoses => new Diagnoses(

              diagnoses._id,
              diagnoses.name,
              diagnoses.description




            ))
            // Not filtering in the server since in-memory-web-api has somewhat restricted api
            .filter(diagnoses => diagnoses.name.includes(filter.name))
          return response;
        }),
        catchError(res => {
          throw (res);
        })
      );
  }


  filterDiagnoses(filter) {
    return this.apiService.post('/diagnose/searchDiagnoses', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


  /**
* get All Diagnoses without med_center (this mean all the forms will be returned for suggestion)
*/
  getAllDiagnosesWithOutMedCenter(details) {
    return this.apiService.post('/diagnose/filterDiagnoses', details)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

}
