import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { SurgicalHistories } from '../main/dashboard/navbar/surgical-histories/surgical-histories.class';


@Injectable({
  providedIn: 'root'
})
export class SurgicalHistoriesService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create a new surgical history
   * @param surgHistory 
   */
  createSurgHistory(surgHistory): Observable<any> {
    return this.apiService.post('/surgicalHistory/new', surgHistory)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all surgical histories
   * @param obj 
   */
  getAllSurgHistories(obj): Observable<any> {
    return this.apiService.post('/surgicalHistory/getSurghistories', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit surgical history
   * @param obj 
   */
  editSurgHistory(obj) {
    return this.apiService.put('/surgicalHistory/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete surgical history
   * @param obj 
   */
  deleteSurgHistory(obj) {
    return this.apiService.post('/surgicalHistory/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * suggest surgical histories when typing surgical history name in add dialog 
   * @param filter 
   * @param page 
   */
  searchSurgHistories(filter: { name: string } = { name: '' }, page = 1): Observable<any> {
    return this.apiService.post('/surgicalHistory/getAll', {})
      .pipe(
        tap((response: any) => {
          response.data = response.data
            .map(surgicalHistories => new SurgicalHistories(
              surgicalHistories.name,
            ))
            // Not filtering in the server since in-memory-web-api has somewhat restricted api
            .filter(surgicalHistories => surgicalHistories.name.includes(filter.name))
          return response;
        }),
        catchError(res => {
          throw (res);
        })
      );
  }

  filterSurgicalHistory(filter) {
    return this.apiService.post('/surgicalHistory/searchSurgHistories', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
 * get All SurgicalHistory without med_center (this mean all the forms will be returned for suggestion)
 */
  getAllSurgicalHistoryWithOutMedCenter(details) {
    return this.apiService.post('/surgicalHistory/filterSurgHistories', details)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }
}
