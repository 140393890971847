import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError,tap } from 'rxjs/operators';
// import { LabReport } from '../checkout/lab-report/labReport.class';

@Injectable({
  providedIn: 'root'
})
export class LabReportsService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }
  /**
   * create new lab report
   * @param labReport 
   */
  createLabReport(labReport): Observable<any> {
    return this.apiService.post('/labReport/new', labReport)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all lap report details
   * @param obj 
   */
  getAllLabReports(obj): Observable<any> {
    return this.apiService.post('/labReport/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit lab report
   * @param obj 
   */
  editLabReport(obj) {
    return this.apiService.put('/labReport/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete lab report
   * @param obj 
   */
  deleteLabReport(obj) {
    return this.apiService.post('/labReport/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  // /**
  //  * suggest lab reports when typing the lab report name in dispenser ui
  //  * @param filter 
  //  * @param page 
  //  */
  // searchLabReports(med_center,filter: { name: string } = { name: '' }, page = 1): Observable<any> {
  //   return this.apiService.post('/labReport/getAll',med_center)
  //     .pipe(
  //       tap((response: any) => {
  //         response.data = response.data
  //           .map(labReport => new LabReport(

  //             labReport._id,
  //             labReport.name,
              

  //           ))
  //           // Not filtering in the server since in-memory-web-api has somewhat restricted api
  //           .filter(labReport => labReport.name.includes(filter.name))
  //         return response;
  //       }),
  //       catchError(res => {
  //         throw (res);
  //       })
  //     );
  // }

  filterLabReport(filter){
    return this.apiService.post('/labReport/searchLabReports', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
 * get All forms without med_center (this mean all the forms will be returned for suggestion)
 */
getAllLabReportsWithMedCenter(med_center) {
  return this.apiService.post('/labReport/getAll', med_center)
    .pipe(map(
      data => {
        return data;
      }
    ),
      catchError(res => {
        throw (res);
      }));
}

}
