import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MedicalHistories } from '../main/dashboard/navbar/medical-histories/medical-histories.class';

@Injectable({
  providedIn: 'root'
})
export class MedicalHistoriesService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create a medical history
   * @param medHistory 
   */
  createMedHistory(medHistory): Observable<any> {
    return this.apiService.post('/medicalHistory/new', medHistory)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all medical history details
   * @param obj 
   */
  getAllMedHistories(obj): Observable<any> {
    return this.apiService.post('/medicalHistory/getMedhistories', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit medical history
   * @param obj 
   */
  editMedHistory(obj) {
    return this.apiService.put('/medicalHistory/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete medical history
   * @param obj 
   */
  deleteMedHistory(obj) {
    return this.apiService.post('/medicalHistory/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * suggest medical histories when typing medical history name in add dialog
   * @param filter 
   * @param page 
   */
  searchMedHistories(filter: { name: string } = { name: '' }, page = 1): Observable<any> {
    return this.apiService.post('/medicalHistory/getAll', {})
      .pipe(
        tap((response: any) => {
          response.data = response.data
            .map(medicalHistories => new MedicalHistories(

              medicalHistories.name,

            ))
            // Not filtering in the server since in-memory-web-api has somewhat restricted api
            .filter(medicalHistories => medicalHistories.name.includes(filter.name))
          return response;
        }),
        catchError(res => {
          throw (res);
        })
      );
  }

  filterMedHistories(filter) {
    return this.apiService.post('/medicalHistory/searchMedHistories', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
 * get All MedHistories without med_center (this mean all the forms will be returned for suggestion)
 */
  getAllMedHistoriesWithOutMedCenter(details) {
    return this.apiService.post('/medicalHistory/filterMedHistories', details)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }
}
