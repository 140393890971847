import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private apiService: ApiService) { }

  /**
* get all prescriptions
* @param obj 
*/
  print(obj): Observable<any> {
    return this.apiService.postPrint('/', obj)
      .pipe(map
        (
          data => {
            return data;
          }
        ),
        catchError(res => {
          throw (res);
        }));
  }



}
