import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Procedure } from '../checkout/dispensary-purchase/dispensary-purchase/procedure.class';
// import { Procedure } from '../dashboard/new-tab/add-prescription/procedure.class';



@Injectable({
  providedIn: 'root'
})
export class ProceduresService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create a new procedure
   * @param procedure 
   */
  createProcedure(procedure): Observable<any> {
    return this.apiService.post('/procedure/new', procedure)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all procedure details
   * @param obj 
   */
  getAllProcedures(obj): Observable<any> {
    return this.apiService.post('/procedure/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit procedure
   * @param obj 
   */
  editProcedure(obj) {
    return this.apiService.put('/procedure/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete procedure
   * @param obj 
   */
  deleteProcedure(obj) {
    return this.apiService.post('/procedure/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

    /**
   * suggest Procedure when typing Procedure name (this is not use. have to remove if not use)
   * @param filter 
   * @param page 
   */
  // getAllProceduresOfMedicalCenter(med_center , filter: { name: string } = { name: '' }, page = 1): Observable<any> {
  //   return this.apiService.post('/procedure/getAll', med_center)
  //     .pipe(
  //       tap((response: any) => {
  //         response.data = response.data
  //           .map(procedure => new Procedure(
  //             procedure.name,
  //             procedure.price,
  //             procedure._id
  //           ))
  //           // Not filtering in the server since in-memory-web-api has somewhat restricted api
  //           .filter(procedure => procedure.name.includes(filter.name))
  //         return response;
  //       }),
  //       catchError(res => {
  //         throw (res);
  //       })
  //     );
  // }

  getAllProceduresOfMedicalCenterNewRoute(med_center , filter: { name: string } = { name: '' }, page = 1): Observable<any> {
    return this.apiService.post('/procedure/searchProcedures', med_center)
      .pipe(
        tap((response: any) => {
          response.data = response.data
            .map(procedure => new Procedure(
              procedure.name,
              procedure.price,
              procedure._id
            ))
            // do not allow filter data for angular. don't un-comment below line
            // .filter(procedure => procedure.name.includes(filter.name))
          return response;
        }),
        catchError(res => {
          throw (res);
        })
      );
  }

  /**
   * this is not user anymore. remove if it is not use
   * @param filter 
   */
  filterProcedures(filter){
    return this.apiService.post('/procedure/searchProcedures', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  } 
}
