import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedicalRecordsService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create a medical record
   * @param medRecord 
   */
  createMedicalRecords(medRecord): Observable<any> {
    return this.apiService.post('/medRecordType/new', medRecord)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * create new parameter
   * @param parameter 
   */
  createType(parameter): Observable<any> {
    return this.apiService.post('/parameter/new', parameter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all medical record details
   * @param obj 
   */
  getAllMedicalRecords(obj): Observable<any> {
    return this.apiService.post('/medRecordType/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all parameter details
   * @param obj2 
   */
  getAllModalDetails(obj2): Observable<any> {
    return this.apiService.post('/parameter/getAll', obj2)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit medical record
   * @param obj 
   */
  editMedicalRecord(obj) {
    return this.apiService.put('/medRecordType/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete medical record
   * @param obj 
   */
  deleteMedicalRecord(obj) {
    return this.apiService.post('/medRecordType/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete parameter
   * @param obj 
   */
  deleteModalDetals(obj) {
    return this.apiService.post('/parameter/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * search medical records
   * @param filter 
   */
  searchMedicalRecords(filter): Observable<any> {
    return this.apiService.post('/medRecordType/searchMedRecord', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

}
