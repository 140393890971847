import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private apiService: ApiService) { }

  notifications : any = [];

  /**
   * set patient request notifications
   * @param notification 
   */
  setUserNotifications(notification){
    this.notifications = notification;
  }

  /**
   * get patient request notification
   */
  getUserNotifications(){
    return this.notifications;
  }

  /**
   * get details of an user
   * @param obj 
   */
  getAllUserDetails(obj): Observable<any> {
    return this.apiService.post('/user/getUser', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit user details
   * @param obj 
   */
  editUserProfile(obj) {
    return this.apiService.put('/user/editDispenser', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * change password of an user
   * @param obj 
   */
  changePassword(obj) {
    return this.apiService.put('/user/changePassword', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

   /**
   * set is_first_login to false when click on skip in change password ui
   * @param obj 
   */
  setIsFirstLoginToFalse(obj) {
    return this.apiService.put('/user/editAdmin', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

}
