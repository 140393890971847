import { Injectable } from '@angular/core';
import { JwtService } from './jwt-service.service';
import { ApiService } from './api-service.service';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SuperAdminDctorsService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }


  /**
* get all doctors of super admin
* @param obj 
*/
  getAllSuperAdminDoctor(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


  /**
* create a new specialist types
* @param obj 
*/
  createSuperAdminDoctor(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/new', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* update super admin doctor
* @param obj 
*/
  updateSuperAdminDoctor(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/update', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* reset super admin doctor password
* @param obj 
*/
  resetSuperAdminDoctorPassword(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/resetPassword', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* delete super admin doctor
* @param obj 
*/
  deleteSuperAdminDoctor(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/delete', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


  /**
* filter super admin doctors
* @param obj 
*/
  filterSuperAdminDoctor(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/filter', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* filter super admin doctors real time when key up
* @param obj 
*/
  filterSuperAdminDoctorsRealTime(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/filterRealTime', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* get super admin doctor by ID
* @param obj 
*/
  getSuperAdminDoctorByID(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/getByID', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* assign doctor to med center
* @param obj 
*/
  assignDoctorToMedCenter(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/assignToMedCenter', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* assign doctor to med center
* @param obj 
*/
  getAllAssignedDoctorOfMedCenter(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/getAllAssignedDoctors', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* change med center doctor access mode (lock or unlock)
* @param obj 
*/
  changeDoctorLoginAccessStatus(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/changeAccessOfMedCenter', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* get super admin doctor permissions that assigned by med center admin
* @param obj 
*/
  getDoctorPermissionRoutes(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/getMedCenterAssignedRoutes', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
* update super admin doctor permissions that assigned by med center admin
* @param obj 
*/
  updateDoctorPermissionRoutes(obj): Observable<any> {
    return this.apiService.post('/superAdminDoctor/updateMedCenterAssignedRoutes', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }
}
