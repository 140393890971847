import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError,tap } from 'rxjs/operators';
// import { Lab } from '../checkout/lab-report/lab.class';

@Injectable({
  providedIn: 'root'
})
export class LabsService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create new lab
   * @param lab 
   */
  createLab(lab): Observable<any> {
    return this.apiService.post('/lab/new', lab)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all lab details
   */
  getAllLabs(obj): Observable<any> {
    return this.apiService.post('/lab/getAll',obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit lab details
   * @param obj 
   */
  editLab(obj) {
    return this.apiService.put('/lab/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete lab
   * @param obj 
   */
  deleteLab(obj) {
    return this.apiService.post('/lab/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * suggest labs when typing the lab name in dispenser ui
   * @param filter 
   * @param page 
   */
  // searchLabs(med_center,filter: { name: string } = { name: '' }, page = 1): Observable<any> {
  //   return this.apiService.post('/lab/getAll',med_center)
  //     .pipe(
  //       tap((response: any) => {
  //         response.data = response.data
  //           .map(lab => new Lab(

  //             lab._id,
  //             lab.name,
  //             lab.percentage,
              

  //           ))
  //           // Not filtering in the server since in-memory-web-api has somewhat restricted api
  //           .filter(lab => lab.name.includes(filter.name))
  //         return response;
  //       }),
  //       catchError(res => {
  //         throw (res);
  //       })
  //     );
  // }

  filterLabs(filter){
    return this.apiService.post('/lab/searchLabs', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }
}
