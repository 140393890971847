import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  constructor(private apiService: ApiService) { }


  /**
   * 
   * @param obj filter patients using a keyword
   */
  filterPatients(obj) {
    return this.apiService.post('/patient/queue/searchPatientForQueue', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  addPatientToTheQueue(obj) {
    return this.apiService.post('/patient/queue/new', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  getQueuedPatients(obj) {
    return this.apiService.post('/patient/queue/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


  deletePatientFromQueue(obj) {
    return this.apiService.post('/patient/queue/delete', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  removeAllQueuedPatients(obj) {
    return this.apiService.post('/patient/queue/deleteAllPatients', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }
}
