import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class OutcomesService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }


  /**
   * create new outcome
   * @param outcome 
   */
  createOutcome(outcome): Observable<any> {
    return this.apiService.post('/outcomeType/new', outcome)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * create new parameter
   * @param parameter 
   */
  createParameter(parameter): Observable<any> {
    return this.apiService.post('/parameter/new', parameter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all outcome details
   * @param obj 
   */
  getAllOutcomes(obj): Observable<any> {
    return this.apiService.post('/outcomeType/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all parameter details
   * @param obj2 
   */
  getAllModalDetails(obj2): Observable<any> {
    return this.apiService.post('/parameter/getAll', obj2)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit outcome
   * @param obj 
   */
  editOutcome(obj) {
    return this.apiService.put('/outcomeType/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete outcome
   * @param obj 
   */
  deleteOutcome(obj) {
    return this.apiService.post('/outcomeType/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete parameter
   * @param obj 
   */
  deleteModalDetals(obj) {
    return this.apiService.post('/parameter/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * search outcomes
   * @param filter 
   */
  searchOutcomes(filter): Observable<any> {
    return this.apiService.post('/outcomeType/searchOutcome', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

}
