import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Morbidity } from '../main/dashboard/navbar/morbidities/morbidity.class';



@Injectable({
  providedIn: 'root'
})
export class MorbiditiesService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create a new morbidity
   * @param morbidity 
   */
  createMorbidity(morbidity): Observable<any> {
    return this.apiService.post('/morbidity/new', morbidity)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all morbidity details
   * @param obj 
   */
  getAllMorbidities(obj): Observable<any> {
    return this.apiService.post('/morbidity/getMorbidities', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit morbidity
   * @param obj 
   */
  editMorbidity(obj) {
    return this.apiService.put('/morbidity/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete morbidity
   * @param obj 
   */
  deleteMorbidity(obj) {
    return this.apiService.post('/morbidity/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * suggest morbidities when typing morbidity name in add dialog
   * @param filter 
   * @param page 
   */
  searchMorbidities(filter: { name: string } = { name: '' }, page = 1): Observable<any> {
    return this.apiService.post('/morbidity/getAll', {})
      .pipe(
        tap((response: any) => {
          response.data = response.data
            .map(morbidity => new Morbidity(

              morbidity.name,
              morbidity.description,

            ))
            // Not filtering in the server since in-memory-web-api has somewhat restricted api
            .filter(morbidity => morbidity.name.includes(filter.name))
          return response;
        }),
        catchError(res => {
          throw (res);
        })
      );
  }

  filterMorbidities(filter) {
    return this.apiService.post('/morbidity/searchMorbidities', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
 * get All Morbidities without med_center (this mean all the forms will be returned for suggestion)
 */
  getAllMorbiditiesWithOutMedCenter(details) {
    return this.apiService.post('/morbidity/filterMorbidities', details)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }
}
