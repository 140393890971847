import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DispensarySalesService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

   /**
   * get all dispensary sales
   * @param obj 
   */
  getAllDispensarySales(obj): Observable<any> {
    return this.apiService.post('/sales/dispensary', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete dispensary sales
   * @param obj 
   */
  deleteSales(obj) {
    return this.apiService.post('/sales/removePurchase', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


    /**
   * get all lab sales
   * @param obj 
   */
  getAllLabSales(obj): Observable<any> {
    return this.apiService.post('/sales/labReports', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  filterDispensarySales(filter){
    return this.apiService.post('/sales/searchDispensary', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  filterLabReportSales(filter){
    return this.apiService.post('/sales/searchLabReports', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  
}
