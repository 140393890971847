import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvestigationsService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create a new investigation
   * @param investigation 
   */
  createInvestigation(investigation): Observable<any> {
    return this.apiService.post('/investigationType/new', investigation)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * create a new parameter
   * @param parameter 
   */
  createType(parameter): Observable<any> {
    return this.apiService.post('/parameter/new', parameter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all investigation details
   * @param obj 
   */
  getAllInvestigations(obj): Observable<any> {
    return this.apiService.post('/investigationType/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all parameters details
   * @param obj2 
   */
  getAllModalDetails(obj2): Observable<any> {
    return this.apiService.post('/parameter/getAll', obj2)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit investigation
   * @param obj 
   */
  editInvestigation(obj) {
    return this.apiService.put('/investigationType/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete investigation
   * @param obj 
   */
  deleteInvestigation(obj) {
    return this.apiService.post('/investigationType/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete parameter
   * @param obj 
   */
  deleteModalDetals(obj) {
    return this.apiService.post('/parameter/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * search investigations
   * @param filter 
   */
  searchInvestigations(filter): Observable<any> {
    return this.apiService.post('/investigationType/searchInvestigation', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

}
