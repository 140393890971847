import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExpensesTypesService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create a expenses type
   * @param expType 
   */
  createExpType(expType): Observable<any> {
    return this.apiService.post('/expenseType/new', expType)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all expenses details
   * @param obj 
   */
  getAllExpTypes(obj): Observable<any> {
    return this.apiService.post('/expenseType/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit expenses type
   * @param obj 
   */
  editExpType(obj) {
    return this.apiService.put('/expenseType/edit', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete expenses type
   * @param obj 
   */
  deleteExpType(obj) {
    return this.apiService.post('/expenseType/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  filterExpensesTypes(filter){
    return this.apiService.post('/expenseType/searchExpTypes', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


}
