import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  currentPrescription: any;


  /**
   * get patients array
   */
  getPrescription() {
    return this.currentPrescription;
  }


  /**
   * set patient details into array
   */
  setPrescription(prescription) {
    this.currentPrescription = prescription
  }


  /**
 * get all allergies
 * @param obj 
 */
  getAllPrescriptionsForDispenser(obj): Observable<any> {
    return this.apiService.post('/prescription/checkoutList', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

   /**
   * 
   * @param obj filter patients using a keyword
   */
  filterPatientsFromOwnMedCenter(obj) {
    return this.apiService.post('/patient/search', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


  /**
    * add new lab report to get income
    * @param obj 
    */
   getIncomeOfTheLabReports(obj): Observable<any> {
    return this.apiService.post('/sales/newLabReport', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


   /**
    * add new lab report to get income
    * @param obj 
    */
   addDispensaryPrescription(obj): Observable<any> {
    return this.apiService.post('/sales/newDispensary', obj) 
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


}




