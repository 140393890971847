import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedcenterService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create a new medical center
   * @param med 
   */
  createMedcenter(med): Observable<any> {
    return this.apiService.post('/user/newAdmin', med)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all medical center details
   */
  getAllMedcenters(obj): Observable<any> {
    return this.apiService.post('/medCenter/getAll',obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete medical center
   * @param obj 
   */
  deleteMedcenter(obj) {
    return this.apiService.put('/medCenter/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * disable medical center
   * @param obj 
   */
  disableMedcenter(obj) {
    return this.apiService.put('/medCenter/disable', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * reset password of the medical center admin
   * @param obj 
   */
  resetMedcenter(obj) {
    return this.apiService.put('/medCenter/resetPassword', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

/**
   * edit payment of the medical center 
   * @param obj 
   */
  editPayment(obj) {
    return this.apiService.put('/medCenter/editMedCenter', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  filterMedicalCenters(filter){
    return this.apiService.post('/medCenter/searchMedCenters', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }



}

