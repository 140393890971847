import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { JwtService } from './jwt-service.service';
import { Observable, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  /**
   * create new doctor
   * @param doctor 
   */
  createDoctor(doctor): Observable<any> {
    return this.apiService.post('/user/newDoctor', doctor)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * get all doctor details
   * @param obj 
   */
  getAllDoctors(obj): Observable<any> {
    return this.apiService.post('/user/getAll', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * edit doctor details
   * @param obj 
   */
  editDoctor(obj) {
    return this.apiService.put('/user/editDoctor', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * delete doctor
   * @param obj 
   */
  deleteDoctor(obj) {
    return this.apiService.post('/user/remove', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

  /**
   * reset password of the doctor
   * @param obj 
   */
  resetPassword(obj) {
    return this.apiService.put('/user/resetPassword', obj)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }


  filterDoctors(filter){
    return this.apiService.post('/user/searchDoctors', filter)
      .pipe(map(
        data => {
          return data;
        }
      ),
        catchError(res => {
          throw (res);
        }));
  }

}

